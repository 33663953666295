import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";

export default defineNuxtRouteMiddleware(async (to, from, next) => {
  if (!process.server) {
    const useAuth = useAuthStore();

    const { userInfo } = storeToRefs(useAuth);
    if (!userInfo.value.show_changePassword) {
      throw createError({ statusCode: 404, statusMessage: "Access denied!!" });
    }
  }
});
